@page {
  margin: 1.5cm; // margin for each printed piece of paper
}

@page :first {
  margin-top: 2cm; // top margin for first page of paper
}
@media print{
  .site-wrapper{
    display: block;
  }

  #root .case-body div section.accordion button:after{
    content:none;
  }
  #root div .result-card .card-tags .tag, #root .related-card .card-tags .tag{
    background-color: transparent;
    padding:0;
    color:#000000;
    &::after{
      content:", ";
    }
  }
  #root div .result-card .card-tags a:last-child .tag, #root .related-card .card-tags a:last-child .tag{
    &::after{
      content:none;
    }
  }
  #root div .result-card .card-tags,  #root .related-card .card-tags{
    margin-bottom:20px;
  }

  #root .row{
    display:block;
  }

  body{
    margin:0;
  }
  .accordion, p, .side-card , .side-card div, .result-card{
    page-break-inside: avoid
  }
  .case-body{
    page-break-after: always;
  }
  .related-cases-title{
    page-break-before: always;
  }
  .result-target{
    display: block;
    width:100%;
  }
  .accordion-inner{
    padding:1.5rem;
  }
  .h3, h3{
    font-size:1.5rem;
  }
  #root .case-section p{
    color:#000000;
  }
  #root .case-body section.accordion button{
    padding-bottom:0;
    font-size:1.75rem;
  }
  #root .side-card .paragraph a button, #root .side-card .tags a button{
    background-color: transparent;
    border-radius: 0;
    padding:0;
    color:#000000;
    font-size:1rem;
    &::after{
      content:", "
    }
  }
  #root .side-card .paragraph a:last-child button, #root .side-card .tags a:last-child button{
    &::after{
      content:none;
    }
  }
  #root .case-section .returnBtn, #root .case-body .see-all-accordions .see-all-accordions-button{
    display: none;
  }
}
#root {
  background-color: #f1f3f5;
  .container {
    max-width: 931px;
    padding-left: 10px;
    padding-right: 10px;

    button {
      box-shadow: none !important;
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;

      &:hover {
        box-shadow: none !important;
      }
    }
  }
  .dropbtn {
    background-color: white;
    color: #698592;
    padding: 17px 0 12px 1rem;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border-radius: 6px;
    position: relative;
    font-weight: normal !important;

    .arrow-icon {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);

      .left-bar {
        position: absolute;
        background-color: transparent;
        top: 50%;
        left: 2px;
        width: 8px;
        height: 2px;
        display: block;
        transform: rotate(35deg);
        float: right;
        border-radius: 2px;
        &:after {
          content: '';
          background-color: #698592;
          width: 8px;
          height: 2px;
          display: block;
          float: right;
          border-radius: 6px 10px 10px 6px;
          transition: all 0.3s ease;
          z-index: -1;
        }
      }

      .right-bar {
        position: absolute;
        background-color: transparent;
        top: 50%;
        right: 4px;
        width: 8px;
        height: 2px;
        display: block;
        transform: rotate(-35deg);
        float: right;
        border-radius: 2px;
        &:after {
          content: '';
          background-color: #698592;
          width: 8px;
          height: 2px;
          display: block;
          float: right;
          border-radius: 10px 6px 6px 10px;
          transition: all 0.3s ease;
          z-index: -1;
        }
      }

      &.open {
        .left-bar:after {
          transform-origin: center center;
          transform: rotate(-70deg);
        }
        .right-bar:after {
          transform-origin: center center;
          transform: rotate(70deg);
        }
      }
    }

    &.sortselectbtn {
      border: 1px solid #698592;
    }
  }
  a {
    text-decoration: none !important;
  }
  .alert-box {
    padding-bottom: 1.5rem;
    .alert-background {
      background-color: white;
      padding: 1.5rem;
    }
  }
  .h3 {
    color: #334f59;
  }
  .clearSearch {
    text-decoration: none;
    @media screen and (max-width: 770px) {
      padding-left: 0;
    }
    &:hover,
    &:focus {
      background-color: transparent;
      text-decoration: underline;
      border: none;
    }
    &:active {
      background-color: transparent;
      border: none;
    }
  }
  .submitBtn {
    background-color: #b83f3f;
    color: white;
    border-radius: 25px;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 14px;
    width: 200px;
    height: 40px;
    line-height: 1;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;

    &:hover {
      background-color: #923232;
      color: white;
    }
  }

  .btn:not(.submitBtn):not(.returnBtn) {
    color: white;
    text-decoration: none;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;

    &:hover {
      text-decoration: underline;
      color: white;
    }
  }

  .result-target {
    position: relative;
    z-index: 1;
  }
  .custom-row {
    position: relative;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 770px) {
      flex-direction: row;
    }
    .search-icon {
      position: absolute;
      right: 15px;
      top: 30px;
      width: 30px;
      height: 30px;
      transform: translateY(-50%);

      .search-circle {
        position: absolute;
        top: 5px;
        width: 15px;
        height: 15px;
        border: 2px solid #2e526f;
        border-radius: 30px;
        display: block;
      }
      .search-handle {
        width: 2px;
        height: 10px;
        background-color: #2e526f;
        display: block;
        position: absolute;
        right: 15px;
        bottom: 3px;
        transform: rotate(-40deg);
      }
    }
    [class*='col-'] {
      padding: 0 !important;
    }
  }
  .result-card,
  .related-card {
    background-color: white;
    padding: 1.5rem;
    margin-bottom: 1.2rem;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    //z-index: 1;
    border: 1px solid white;
    color: #243847;
    text-decoration: none;
    &:hover {
      border: 1px solid #b83f3f;
    }

    &.news-card {
      background-color: #2e526f;
      color: white;

      .card-message {
        margin-bottom: 1.5rem;
        font-size: 14px;
      }
    }
    span {
      color: #698592fd;
      font-size: 14px;
      font-style: italic;
    }
    h2 {
      font-size: 18px;
    }
    .card-tags {
      .tag {
        background-color: #3e9dc1;
        color: white;
        border: none;
        padding: 4px 15px;
        border-radius: 15px;
        margin-right: 5px;
        margin-bottom: 5px;
        font-size: 12px;
        cursor: pointer;
        position: relative;
        z-index: 22;
        transition: 0.3s ease;
        &.active {
          background-color: #307090 !important;
        }

        &:hover {
          background-color: #2e526f;
        }
      }
    }
    .card-message {
      font-size: 14px;
      margin: 0;
      color: #2e526f;
    }
    .special-tag {
      position: absolute;
      right: 0;
      padding: 2px 12px 2px 12px;
      background-color: #b83f3f;
      color: white;
      top: 10px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    &.campaign {
      background-color: #2e526f;
      color: white;
      border: none;
      pointer-events: none;
      &:hover {
        border: none;
      }

      a {
        display: inline-block;
        color: white;
        background: #b83f3f;
        border-radius: 40px;
        padding: 6px 20px !important;
        margin-top: 1rem;
        transition: 0.3s ease;
        pointer-events: all;

        &:hover {
          background: #923232;
        }
      }
      .card-message {
        color: white;
      }
    }
    &.campaign-subpage {
      background: transparent;
      pointer-events: none;

      &:hover {
        border: 1px solid white;
      }
      .date-placeholder {
        height: 20px;
        width: 100%;
        display: block;
        position: relative;
        margin-top: 30px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          width: 40px;
          height: 5px;
          background: #b83f3f;
        }
      }
      a {
        color: white;
        background: #b83f3f;
        padding: 5px 20px !important;
        border-radius: 40px;
        display: inline-block;
        transition: 0.3s ease;
        pointer-events: all;

        &:hover {
          background: #923232;
        }
      }
    }
  }

  .result-target {
    a.card-title {
      color: #243847;;
    }
  }
  .special-tag {
    position: absolute;
    right: 0;
    padding: 2px 12px 2px 12px;
    background-color: #b83f3f;
    color: white;
    top: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .side-card {
    background-color: white;
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    margin-bottom: 1rem;
    border-radius: 6px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 30px;
      left: 1.5rem;
      background-color: #b83f3f;
      width: 50px;
      height: 5px;
    }
    .h3 {
      font-size: 18px;
      font-weight: bold;
      color: #334f59;
      margin-bottom: 0.2rem;
    }
    .paragraph,
    .tags {
      flex-wrap: wrap;
      display: flex;

      button {
        transition: 0.3s ease;
        &:hover {
          background-color: #2e526f;
        }
      }
    }
    span {
      display: inline-block;
      margin-bottom: 0.5rem;
    }
    button {
      background-color: #3e9dc1;
      color: white;
      border: none;
      padding: 4px 15px;
      border-radius: 15px;
      margin-right: 5px;
      margin-bottom: 5px;
      font-size: 12px;
      cursor: pointer;
      transition: 0.3s ease;

      &:hover {
        background-color: #2e526f;
      }
    }
    .status {
      .red,
      .green {
        display: inline-block;
        margin: 0 10px 0 6px;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        &.red {
          background: #b83f3f;
        }
        &.green {
          background: #92b573;
        }
      }
    }
  }
  a {
    display: block;
    color: #b83f3f;
  }

  .results-footer {
    .page-information {
      display: flex;
      justify-content: space-between;

      .btn {
        color: #212529;
        text-decoration: none;

        &:hover {
          color: #b83f3f;
          text-decoration: none;
        }
      }
    }

    .paging {
      display: flex;
      max-width: 600px;
      margin: auto;
      justify-content: space-between;
      padding: 2.5rem 0;
      .redbtn {
        border: none;
        background-color: #b83f3f;
        color: white;
        padding: 9px 34px;
        border-radius: 40px;
        transition: 0.3s ease;

        &:hover {
          background-color: #923232;
        }
      }
      .pages {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        span {
          padding: 0 10px;
        }
      }

      .pages {
        .page-item {
          &.active {
            button {
              color: #b83f3f;
            }
          }
          button {
            border: none;
            background-color: transparent;
            &:hover {
              color: #b83f3f;
            }
          }
        }
      }
    }
  }

  .search-categories {
    padding: 10px 10px 0 10px;
    position: relative;
    background-color: white;
    @media screen and (min-width: 767px) {
      padding: 0 56px 0 56px;
    }
    .category-toggle {
      border: none;
      background: transparent;
      display: block;
      font-size: 20px;
      text-decoration: none;
      padding: 7px 0;
      cursor: pointer;
      width: 100%;
      text-align: left;
      @media screen and (min-width: 667px) {
        display: none;
      }
    }
    .mynav {
      position: relative;
      @media screen and (max-width: 667px) {
        height: 0;
        overflow: hidden;
        &.open {
          height: auto;
        }
      }
    }
    .mynav ul {
      list-style-type: none;
      padding: 0;
      @media screen and (min-width: 767px) {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
      }
      li {
        font-weight: 600;

        &:before {
          display: none !important;
        }
      }
      li:not(:last-child) {
        margin-right: 50px;
      }
      li.active {
        button {
          color: #243847;
          position: relative;
          font-weight: bold;

          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            background-color: #b83f3f;
            left: 0;
            bottom: 0;
          }
        }
      }
      button {
        display: block;
        font-size: 16px;
        color: #698592;
        text-decoration: none;
        padding: 7px 0;
        cursor: pointer;
        background-color: transparent;
        border: none;
      }
    }
  }

  .search-filter-column {
    @media screen and (min-width: 767px) {
      flex: 0 0 50%;
      &:first-of-type {
        padding-right: 10px;
      }
      &:last-of-type {
        padding-left: 10px;
      }
    }
  }

  select {
    width: 100%;
    padding: 17px 0 12px 0.5rem;
    border-radius: 5px;
    border: none;
    color: #698592;
  }

  .subject-dropdown,
  .law-dropdown,
  .sort-dropdown,
  .year-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    .wrap-input {
      margin: 1rem 0 1rem 1rem;
      position: relative;
    }
    #subjectInput {
      box-sizing: border-box;
      // background-image: url('searchicon.png');
      background-position: 14px 12px;
      background-repeat: no-repeat;
      font-size: 16px;
      padding: 14px 20px 12px 15px;
      border: none;
      border: 1px solid #4d708f;
      width: 92%;
      height: auto !important;
    }
    .search-icon {
      position: absolute;
      right: 30px;
      top: 25px;
      width: 30px;
      height: 30px;

      .search-circle {
        position: absolute;
        top: 8px;
        width: 12px;
        height: 12px;
        border: 2px solid #2e526f;
        border-radius: 30px;
        display: block;
      }
      .search-handle {
        width: 2px;
        height: 7px;
        background-color: #2e526f;
        display: block;
        position: absolute;
        right: 17px;
        bottom: 5px;
        transform: rotate(-40deg);
      }
    }
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: white;
      min-width: 230px;
      overflow: auto;
      border: 1px solid #ddd;
      z-index: 999;
      width: 100%;
      margin-top: 10px;
      &.show {
        display: block;
      }
    }

    .dropdown-content .scroll-list span {
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      cursor: pointer;
    }

    &.sort-dropdown {
      .dropdown-content {
        min-width: auto;
      }
    }
    &.year-dropdown {
      .dropdown-content {
        min-width: auto;
      }
      .scroll-list {
        max-height: 250px;
      }
    }
  }

  .scroll-list {
    max-height: 400px;
    overflow-y: auto;

    span {
      color: #4d708f;
      font-family: 'Lato', sans-serif;
      &:hover {
        font-weight: bold;
        background-color: #f1f3f5;
      }
    }
  }

  .search-box {
    background-color: #4d708f;
    padding: 10px 10px;
    @media screen and (min-width: 767px) {
      padding: 61px 56px 0 56px;
    }

    .col {
      padding: 0;
    }
    input {
      margin: 0;
    }
    label {
      color: white;
      margin-bottom: 0.5rem;
    }
    #search-text {
      padding: 17px 0 12px 0.5rem;
      margin-bottom: 1.5rem;
      height: auto !important;
      font-size: 16px !important;

      &::placeholder {
        font-style: italic;
        font-weight: normal;
      }
    }
    .mobile-overlay {
      position: absolute;
      right: 1px;
      top: 2px;
      width: 70px;
      height: 45px;
      background-color: white;
      @media screen and (min-width: 518px) {
        display: none;
      }

      span {
        position: absolute;
        top: 50%;
        transform: translateY(-32%);
        left: 2px;
        color: #698592;
      }
    }
    h3 {
      color: white;
    }
    .search-advanced-container {
      position: relative;

      .button-row {
        position: absolute;
        right: 0;
        bottom: -5%;
        display: flex;
        align-items: center;
        @media screen and (min-width: 770px) {
          bottom: 15%;
        }
      }
    }
    .advanced-search-button {
      margin: 2.5rem 0 6.5rem 0;

      @media screen and (min-width: 600px) {
        margin: 1.5rem 0;
      }
      @media screen and (min-width: 770px) {
        margin: 2.5rem 0;
      }
      &.isOpen {
        @media screen and (max-width: 770px) {
          margin-bottom: 1rem;
        }
      }
      button {
        padding-left: 0;
        position: relative;
        padding-right: 40px;
        font-weight: normal !important;
        text-decoration: none;

        &:hover,
        &:focus {
          background-color: transparent !important;
          text-decoration: underline;
          border: none;
        }
        &:active {
          background-color: transparent !important;
          text-decoration: none;
          border: none;
        }

        .arrow-icon {
          position: absolute;
          width: 20px;
          height: 20px;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);

          .left-bar {
            position: absolute;
            background-color: transparent;
            top: 50%;
            left: 2px;
            width: 8px;
            height: 2px;
            display: block;
            transform: rotate(35deg);
            float: right;
            border-radius: 2px;
            &:after {
              content: '';
              background-color: white;
              width: 8px;
              height: 2px;
              display: block;
              float: right;
              border-radius: 6px 10px 10px 6px;
              transition: all 0.3s ease;
              z-index: -1;
            }
          }

          .right-bar {
            position: absolute;
            background-color: transparent;
            top: 50%;
            right: 4px;
            width: 8px;
            height: 2px;
            display: block;
            transform: rotate(-35deg);
            float: right;
            border-radius: 2px;
            &:after {
              content: '';
              background-color: white;
              width: 8px;
              height: 2px;
              display: block;
              float: right;
              border-radius: 10px 6px 6px 10px;
              transition: all 0.3s ease;
              z-index: -1;
            }
          }

          &.open {
            .left-bar:after {
              transform-origin: center center;
              transform: rotate(-70deg);
            }
            .right-bar:after {
              transform-origin: center center;
              transform: rotate(70deg);
            }
          }
        }
      }
    }
  }

  .advanced-accordion-outer {
    height: 0;
    transition: 0.3s ease;
    overflow: hidden;

    &.open {
      height: auto;
      overflow: visible;

      + .button-row {
        bottom: 5%;
      }
    }
    .advanced-accordion-inner {
      padding-bottom: 120px;
      .align-items-end {
        @media screen and (max-width: 770px) {
          margin: 1rem 0;
        }
      }
      .h3 {
        color: white;
        font-size: 16px;
      }
    }
  }

  .returnBtn {
    color: #b83f3f !important;
    text-decoration: none !important;
    font-weight: bold !important;
    text-align: left !important;
    padding-left: 0 !important;
    font-size: 14px;
    &:hover {
      color: #923232;
    }
  }

  .related-cases {
    a:first-of-type {
      @media screen and (min-width: 767px) {
        padding-left: 0;
      }
    }
  }

  .related-cases-title {
    div {
      @media screen and (min-width: 767px) {
        padding-left: 10px;
      }
    }
  }

  .case-body {
    .see-all-accordions {
      text-align: right;
      .see-all-accordions-button {
        border: none;
        background-color: transparent;
        margin: 20px 0px;
        color: #b83f3f;
        font-size: 0.9em;
      }
    }
    section.accordion {
      position: relative;
      .accordion-outer {
        height: 0;
        overflow: hidden;
      }
      button {
        position: relative;
        &:hover {
          outline: none;
        }

        &:after {
          content: '+';
          position: absolute;
          right: 0px;
          top: 50%;
          transform: translateY(-50%);
          margin-right: 20px;
          color: #b83f3f;
          font-weight: 700;
          font-size: 1.5em;
        }
      }

      &.open {
        button {
          &:after {
            content: '-';
          }
        }

        .accordion-outer {
          height: auto;
          padding: 20px;
        }
      }
    }
  }

  .search-results {
    .row {
      a {
        display: inline-block;
      }
    }
  }

  .case-section {
    .returnBtn {
      color: #b83f3f !important;
      text-decoration: none !important;
      font-weight: bold !important;
      text-align: left !important;
      padding-left: 1.5rem !important;
      font-size: 14px;
      &:hover {
        color: #923232;
      }
    }
    .header-container {
      background-color: white;
      position: relative;

      .historical-label {
        position: absolute;
        right: 0;
        top: 30px;
        padding: 2px 12px;
        background-color: #b83f3f;
        color: #fff;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }
    .case-header {
      background-color: white;
      padding: 1.5rem 0;
      margin-bottom: 0;
    }
    h1 {
      background-color: white;
      padding: 0 1.5rem 1.5rem 1.5rem;
      margin-bottom: 0;
      color: #243847;
    }
    .manchet {
      color: #2e526f;
      background-color: white;
      margin: 0;
      padding: 0 1.5rem;
      font-size: 18px;
    }
    p {
      color: #2e526f;
    }
    //.case-body {
    section {
      margin-bottom: 1rem;
      background-color: white;
      button {
        width: 100%;
        height: 100%;
        padding: 1.5rem;
        border: none;
        text-align: left;
        position: relative;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        color: #2e526f;
        background-color: transparent;

        .header {
          margin: 0;
        }
      }
    }
    //}
    .case-abstract {
      background-color: white;
      padding: 1.5rem;
      section {
        background-color: white;
        padding: 0 1.5rem;
        margin-bottom: 1rem;

        button {
          padding-left: 0;
          padding-right: 0;
          pointer-events: none;
          &:hover {
            outline: none;
          }

          &:first-of-type {
            color: #2e526f;
            background-color: white;
            margin: 0;
            font-size: 18px;
            font-weight: 500;
          }
          &:after {
            margin-right: 0;
            right: -4px;
            display: none !important;
          }
        }
        .accordion-outer {
          padding: 0 !important;
          height: auto !important;
        }
        &:last-of-type {
          .accordion-outer {
            &:last-of-type {
              padding: 0 0 1.5rem 0 !important;
            }
          }
        }
      }
    }
  }

  .date-row {
    @media screen and (min-width: 767px) {
      display: flex;
    }

    .column-left {
      flex: 0 0 50%;
      input {
        padding: 17px 0 12px 1rem;
        color: #698592;
      }
    }
    .column-right {
      flex: 0 0 50%;
      @media screen and (min-width: 767px) {
        margin-left: 10px;
      }
      input {
        padding: 17px 0 12px 1rem;
        color: #698592;
      }
    }
    .form-label {
      font-family: 'Lato', sans-serif;
    }
  }

  .radio-container {
    display: flex;
    padding-left: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0 !important;
    padding-bottom: 0.75rem;

    &:first-of-type {
      margin-right: 30px;
    }
  }

  .radio-container input {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 10px;
    background-color: #4d708f;
    border: 2px solid white;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 2px solid white;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }

  .radio-container .checkmark:after {
    top: 7px;
    left: 7px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }

  .relatedinfo-wrapper {
    position: relative;
    .relatedinfo-box {
      position: absolute;
      right: 100%;
      top: 10px;
      min-width: 25vw;
      box-shadow: 0 0 40px 4px #c0c0c0;
      background-color: #fff;
      padding: 40px;
    }
  }
  #sorter-row {
    color: #334f59;

    .h3 {
      font-size: 20px;
    }
  }
  @media print {
    .case-body section.accordion .accordion-outer {
      overflow: visible;
      height: auto;
    }
    #sorter-row,
    .search-box,
    .search-categories {
      display: none;
    }
  }
}

/* Plone integration css fixes */
body.subsection-principafgorelser-find-principafgorelse {
  .breadcrumb {
    padding-left: 25px;
  }

  #top-image-container {
    display: none;
  }
  #content {
    background-color: #f1f3f5;
    margin-top: -3px;
    header {
      max-width: 931px;
      padding: 4rem 10px;
      margin: 0 auto;
      @media (min-width: 767px) {
        padding: 4rem 56px;
      }
    }
    .documentDescription {
      margin-bottom: 0;
    }
  }
  #root {
    .container {
      padding: 0;
    }
  }
}
